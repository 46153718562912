.toc {
  .toc-title {
    font-size: $toc-title-font-size;
    font-weight: bold;
    text-transform: uppercase;
  }

  .toc-content {
    font-size: $toc-content-font-size;

    ul {
      text-indent: -0.85rem;
      padding-left: .8rem;
      list-style: none;

      a:first-child::before {
        content: "|";
        font-weight: bolder;
        margin-right: .5rem;
        color: $single-link-color;
      }

      ul {
        padding-left: 1.5rem;
      }
    }
  }

  ruby {
    background: $code-background-color;

    rt {
      color: $global-font-secondary-color;
    }

  }
}

#toc-auto {
  display: block;
  position: absolute;
  padding: 0 .8rem;
  border-left: 4px solid $global-border-color;
  @include overflow-wrap(break-word);
  box-sizing: border-box;
  top: 10rem;
  left: 80%;
  width: 20%;

  [header-desktop=normal] & {
    top: 5rem;
  }

  @include blur;

  .toc-title {
    margin: .8rem 0;
  }

  .toc-content {
    &.always-active ul {
      display: block;
    }

    >nav>ul {
      margin: .625rem 0;
    }

    ul {
      ul {
        display: none;
      }

      .has-active>ul {
        display: block;
      }
    }

    a.active {
      font-weight: bold;
      color: $single-link-color;

      &::before {
        color: $single-link-hover-color;
      }
    }
  }
}

#toc-static {
  display: none;
  margin: .8rem 0;

  &[kept=true] {
    display: block;
  }

  .toc-title {
    display: flex;
    justify-content: space-between;
    line-height: 2em;
    padding: 0 .75rem;
    background: $code-background-color-darken-6;
  }

  .toc-content {
    background-color: $code-background-color;

    >nav>ul {
      margin: 0;
      padding: .4rem 1rem .4rem 1.8rem;
    }
  }

  &.open {
    .toc-title {
      background: $code-header-color;
    }
  }
}